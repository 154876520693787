window.addEventListener('DOMContentLoaded', (event) => {
    const htmlElement = document.documentElement;

    // Header vars
    const headerNavButtonToggle = document.getElementById('js-header__nav-button-toggle');
    const headerOverlay = document.getElementById('js-header__overlay');
    let isHeaderNavsOpened = false;

    // Scroll vars
    let previousKnownScrollPosition = window.scrollY;
    let lastKnownScrollPosition = 0;
    let tickingScroll = false;

    // Account menu vars
    const accountMenuButtonToggle = document.getElementById('js-header__account-button');
    const accountMenu = document.getElementById('js-header__account-menu');
    let isAccountMenuOpened = false;

    // Footer popin vars
    const footerPopin = document.getElementById('js-footer__actions-popin');
    const footerPopinOverlay = document.getElementById('js-footer__actions-popin-overlay');
    const footerPopinButtonsOpen = document.querySelectorAll('.js-footer__actions-popin-button-open');
    const footerPopinButtonClose = document.getElementById('js-footer__actions-popin-button-close');
    let isFooterPopinOpened = false;

    function headerOnScroll() {
        lastKnownScrollPosition = window.scrollY;

        if (!tickingScroll) {
            window.requestAnimationFrame(function() {
                switchHeaderOnScroll(lastKnownScrollPosition);
                tickingScroll = false;
            });

            tickingScroll = true;
        }
    }

    function switchHeaderOnScroll(scrollYPos) {
        if (scrollYPos === 0) {
            htmlElement.classList.remove('js-html--header-small');

            // Safari iOS fix
            htmlElement.classList.remove('js-html--header__top--hidden');
        }
        else {
            htmlElement.classList.add('js-html--header-small');

            if (scrollYPos < previousKnownScrollPosition) {
                htmlElement.classList.remove('js-html--header__top--hidden');
            }
            else {
                htmlElement.classList.add('js-html--header__top--hidden');
            }
        }
        previousKnownScrollPosition = scrollYPos;
    }

    function toggleHeader() {
        if (accountMenu && accountMenu.classList.contains('js-header__account-menu--opened')) {
            toggleAccountMenu();
        }

        isHeaderNavsOpened = !isHeaderNavsOpened;
        htmlElement.classList.toggle('js-html-header--overflow-visible');
        headerNavButtonToggle.setAttribute("aria-expanded", isHeaderNavsOpened);
        htmlElement.classList.toggle('js-html-header--opened');
        htmlElement.classList.toggle('js-html--no-scroll');
    }

    // Init scroll
    document.addEventListener('scroll', headerOnScroll, false);

    //  Init header to prevent transparent header at the middle of the page on reloading
    switchHeaderOnScroll(window.scrollY);

    headerNavButtonToggle.addEventListener('click', function () {
        toggleHeader();
    });

    headerOverlay.addEventListener('click', function () {
        if (htmlElement.classList.contains('js-html-header--opened')) {
            toggleHeader();
        }
    });

    function toggleAccountMenu() {
        if (htmlElement.classList.contains('js-html-header--opened')) {
            toggleHeader();
        }

        isAccountMenuOpened = !isAccountMenuOpened;
        htmlElement.classList.toggle('js-html-header--overflow-visible');
        accountMenuButtonToggle.setAttribute("aria-expanded", isAccountMenuOpened);
        accountMenu.classList.toggle('js-header__account-menu--opened');
    }

    if (accountMenuButtonToggle) {
        accountMenuButtonToggle.addEventListener('click', function () {
            toggleAccountMenu();
        });
    }

    /**
     * When click outside opened accountMenu, close it
     */
    if (accountMenu && accountMenuButtonToggle) {
        document.addEventListener('click', function(event) {

            // Mandatory test: Otherwise !isClickInsideAccountMenu is already true when opening
            let isClickInsideAccountMenuButtonToggle = accountMenuButtonToggle.contains(event.target);
            if (!isClickInsideAccountMenuButtonToggle) {
                if (accountMenu.classList.contains('js-header__account-menu--opened')) {
                    let isClickInsideAccountMenu = accountMenu.contains(event.target);
                    if (!isClickInsideAccountMenu) {
                        toggleAccountMenu();
                    }
                }
            }
        });
    }

    function toggleFooterPopin() {
        isFooterPopinOpened = !isFooterPopinOpened;
        if (footerPopinButtonsOpen) {
            footerPopinButtonsOpen.forEach(function(footerPopinButtonOpen) {
                footerPopinButtonOpen.setAttribute("aria-expanded", isFooterPopinOpened);
            });
        }
        if (footerPopinButtonClose) {
            footerPopinButtonClose.setAttribute("aria-expanded", isFooterPopinOpened);
        }
        footerPopin.classList.toggle('js-footer__actions-popin--opened');
        htmlElement.classList.toggle('js-html--no-scroll');
    }

    if (footerPopinButtonsOpen) {
        footerPopinButtonsOpen.forEach(function(footerPopinButtonOpen) {
            footerPopinButtonOpen.addEventListener('click', function () {
                toggleFooterPopin();
            });
        });
    }

    if (footerPopinButtonClose) {
        footerPopinButtonClose.addEventListener('click', function () {
            toggleFooterPopin();
        });
    }

    if (footerPopinOverlay) {
        footerPopinOverlay.addEventListener('click', function () {
            toggleFooterPopin();
        });
    }
});
