window.addEventListener('DOMContentLoaded', () => {
  const axeptioDatas = document.getElementById('bnee_axeptio_data');

  if (axeptioDatas) {
    const clientId = axeptioDatas.dataset.clientid;
    const cookiesVersion = axeptioDatas.dataset.cookiesversion;
    const googleTagManagerId = axeptioDatas.dataset.googletagmanagerid;
    const userCookiesDomain = axeptioDatas.dataset.usercookiesdomain;

    window.axeptioSettings = {
      clientId: clientId,
      cookiesVersion: cookiesVersion,
      userCookiesDomain: userCookiesDomain,
      googleConsentMode: {
        default: {
          analytics_storage: "denied",
          ad_storage: "denied",
          ad_user_data: "denied",
          ad_personalization: "denied",
          wait_for_update: 500
        }
      }
    };

    (function loadAxeptioSDK(d, s) {
      const t = d.getElementsByTagName(s)[0];
      const e = d.createElement(s);
      e.async = true;
      e.src = "//static.axept.io/sdk.js";
      t.parentNode.insertBefore(e, t);
    })(document, "script");

    const lang = document.documentElement.getAttribute('lang');

    /**
     * Association between Axeptio vendor id and vendor name displayed in bne-cookies-consent__overlay.
     * It is not required to add vendor names here if name is just vendor id with uppercase first letter.
     * @type {{youtube: string, youtube_API: string, google_recaptcha: string, modio: string}}
     */
    let vendorNames = {
      'youtube': 'YouTube',
      'youtube_API': 'YouTube JS API',
      'modio': 'Mod.io',
      'google_recaptcha': 'reCAPTCHA',
    }

    const trans = {
      'fr': {
        'allow': 'Autoriser',
        'fallback': 'est désactivé.',
      },
      'en': {
        'allow': 'Allow',
        'fallback': 'is disabled.',
      },
      'de': {
        'allow': 'Erlauben',
        'fallback': 'ist deaktiviert.',
      },
      'it': {
        'allow': 'Consenti',
        'fallback': 'è disattivato.',
      },
      'es': {
        'allow': 'Permitir',
        'fallback': 'está deshabilitado.',
      },
      'ar': {
        'allow': 'Allow',
        'fallback': 'is disabled.',
      },
      'ru': {
        'allow': 'Позволить',
        'fallback': 'Деактивирован.',
      },
    }

    let gtmTagLoaded = false;
    let youTubeJSApiTagLoaded = false;
    let recaptchaTagLoaded = false;

    function getElementWidth(element) {
      return element.getAttribute('width') || element.clientWidth;
    }

    function getElementHeight(element) {
      return element.getAttribute('height') || element.clientHeight;
    }

    document
      .querySelectorAll('[data-requires-vendor-consent]')
      .forEach(el => {
        const vendor = el.getAttribute('data-requires-vendor-consent');
        const parentEl = el.parentElement;

        // Prevent recreating consent containers multiple times
        if (!parentEl.classList.contains('js-bne-cookies-consent')) {
          // If parent is a responsive-iframe-container, use it to apply consent containers
          if (parentEl.classList.contains('responsive-iframe-container')) {
            parentEl.classList.add('bne-cookies-consent', 'js-bne-cookies-consent');
          } else {
            // Create a parent to apply consent classes
            const currentParentEl = el.parentNode;
            const newParentEl = document.createElement('div');
            newParentEl.classList.add('bne-cookies-consent', 'js-bne-cookies-consent');

            // Parent must preserve element specific size attributes
            let elWidth = getElementWidth(el);
            newParentEl.setAttribute('width', elWidth);

            let elHeight = getElementHeight(el);
            newParentEl.setAttribute('height', elHeight);

            // Parent must preserve element specific styles attribute
            let elStyle = el.getAttribute('style');
            if (elStyle) {
              newParentEl.setAttribute('style', elStyle);
            }

            currentParentEl.replaceChild(newParentEl, el);
            newParentEl.appendChild(el);
          }

          // If Axeptio vendor id misses a vendor name, add it as vendor id with uppercased first letter
          // This allows to skip BNE Links updates with simple vendors (Twitch, Spotify, etc.) and prevent to display
          // undefined instead of the vendor name in bne-cookies-consent__overlay.
          if (!vendorNames[vendor]) {
            vendorNames[vendor] = vendor[0].toUpperCase() + vendor.slice(1);
          }

          // Add consent overlay
          el.insertAdjacentHTML('afterend', `<div data-hide-on-vendor-consent="${vendor}" class="bne-cookies-consent__overlay">${vendorNames[vendor]} ${trans[lang]['fallback']}<button type="button" class="bne-btn bne-cookies-consent__cta" onclick="window.axeptioHandleVendors({'${vendor}': true})">${trans[lang]['allow']}</button></div>`);
        }
      });

    /**
     * Load Google Tag Manager tag
     */
    function loadGTMTag(loadedOnce) {
      if (!loadedOnce) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js'
        });

        const firstScriptTag = document.getElementsByTagName("script")[0];
        const tag = document.createElement("script");
        tag.async = true;
        tag.src = "https://www.googletagmanager.com/gtm.js?id=".concat(googleTagManagerId);
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        gtmTagLoaded = true;
      }
    }

    function loadYouTubeJSApiTag(loadedOnce) {
      if (!loadedOnce) {
        const tag = document.createElement('script');
        const firstScriptTag = document.getElementsByTagName('script')[0];
        tag.src = 'https://www.youtube.com/iframe_api';
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        youTubeJSApiTagLoaded = true;
      }
    }

    function loadRecaptchaTag(loadedOnce) {
      if (!loadedOnce) {
        const tag = document.createElement('script');
        const firstScriptTag = document.getElementsByTagName('script')[0];
        tag.async = true;
        tag.defer = true;
        tag.src = 'https://www.google.com/recaptcha/api.js';
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        recaptchaTagLoaded = true;
      }
    }

    (window._axcb = window._axcb || []).push(function (sdk) {
      // Event will trigger each time a cookie change (accept or refuse)
      sdk.on('cookies:complete', function (choices) {

        if (choices.googletagmanager) {
          loadGTMTag(gtmTagLoaded);
        }

        document
          .querySelectorAll('[data-requires-vendor-consent]')
          .forEach(el => {
            const vendor = el.getAttribute('data-requires-vendor-consent');

            if (choices[vendor]) {
              // Load iframes if vendor is accepted
              el.setAttribute('src', el.getAttribute('data-src'));

              switch (vendor) {
                case 'youtube_API':
                  loadYouTubeJSApiTag(youTubeJSApiTagLoaded);
                  break;
                case 'modio':
                  window.addEventListener('message', (e) => {
                    // Check if message origin matches with modio iframe data-src
                    if (el.getAttribute('src').includes(e.origin)) {
                      if (Number.isInteger(e.data?.modioHeight)) {
                        // Remove parent fixed height
                        el.parentNode.style.height = 'auto';
                        // Update iframe height
                        el.style.height = `${e.data.modioHeight}px`;
                      }
                      if (e.data?.modioScrollToTop) {
                        el.scrollIntoView();
                      }
                    }
                  });
                  break;
                case 'google_recaptcha':
                  loadRecaptchaTag(recaptchaTagLoaded);
                  break;
              }
            } else {
              // If vendor is NOT accepted
              if (el.getAttribute('src')) {
                // If iframe has a src attribute: consent has been accepted then refused: Force page reload
                window.location.reload();
              }
            }
          });

        document
          .querySelectorAll('[data-hide-on-vendor-consent]')
          .forEach(el => {
            const vendor = el.getAttribute('data-hide-on-vendor-consent');
            el.style.display = choices[vendor] ? 'none' : 'flex';
          });
      });
    });
  } else {
    console.error("Element 'bnee_axeptio_data' not found in DOM.");
  }
});
